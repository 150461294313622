import NewOrderDetailsModal from "../../../components/subReports/newOrderDetailsModal";
import OrderDetailsModal from "../../../components/subReports/orderDetailsModal";

interface ISelectSubReportProps {
  type: string | null;
  rowData: any;
  onClose: () => void;
}
const SelectSubReport = ({ type, rowData, onClose }: ISelectSubReportProps) => {
  switch (type) {
    case "OrderDetails":
      return <NewOrderDetailsModal selectedOrder={rowData} onClose={onClose} />;
    default:
      return <></>;
  }
};

export default SelectSubReport;
